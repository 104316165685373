import React from "react";
import "../Branches.css";
import KellerS1Img from "../../../Assets/images/Branches/Keller/KellerS1Img.webp";
import BigCart from "../../../Component/Branches/BigCart/BigCart";
import BigCartMob from "../../../Component/Branches/BigCart/BigCartMob";
import SmallCart from "../../../Component/Branches/SmallCart/SmallCart";
import SmallCartMob from "../../../Component/Branches/SmallCart/SmallCartMob";
import { Link } from "react-router-dom";
import ButtonIcon from "../../../Assets/svg/Header/Button_Icon.svg";
import SliderBranches from "../../../Component/Branches/SliderBranches/SliderBranches";
import DisplayBranches from "../../../Component/Branches/DisplayBranches/DisplayBranches";
import Grand_Prairie from "../../../Assets/images/Branches/Grand_Prairie.webp";
import Grapevine from "../../../Assets/images/Branches/Grapevine.webp";
import KellerSlider1 from "../../../Assets/images/Branches/Keller/KellerSlider1.webp";
import KellerSlider2 from "../../../Assets/images/Branches/Keller/KellerSlider2.webp";
import KellerSlider3 from "../../../Assets/images/Branches/Keller/KellerSlider3.webp";
import KellerSlider4 from "../../../Assets/images/Branches/Keller/KellerSlider4.webp";
import KellerSlider5 from "../../../Assets/images/Branches/Keller/KellerSlider5.webp";
import KellerSlider6 from "../../../Assets/images/Branches/Keller/KellerSlider6.webp";
import KellerSlider7 from "../../../Assets/images/Branches/Keller/KellerSlider7.webp";
import KellerSlider8 from "../../../Assets/images/Branches/Keller/KellerSlider8.webp";
import KellerSlider9 from "../../../Assets/images/Branches/Keller/KellerSlider9.webp";
import KellerSlider10 from "../../../Assets/images/Branches/Keller/KellerSlider10.webp";
import KellerSlider11 from "../../../Assets/images/Branches/Keller/KellerSlider11.webp";
import KellerSlider12 from "../../../Assets/images/Branches/Keller/KellerSlider12.webp";

import { kellerBigCartData } from "../ServicesData/KellerData";
import { kellerSmallCartsData } from "../ServicesData/KellerData";

export default function KellerContent() {
  return (
    <>
      <div className="BranchMainContainer">
        <BranchMainS1 />
        <BranchMainS2 />
        <BranchMainS3 />
        <BranchMainS4 />
        <BranchMainS5 />
      </div>
    </>
  );
}

function BranchMainS1() {
  return (
    <>
      <div className="BranchMainS1">
        <div className="BranchMainS1P1">Welcome to Our </div>
        <div className="BranchMainS1P2">Keller Location </div>
        <div className="BranchMainS1P3">
          <img src={KellerS1Img} alt="wash your stuff keller" className="BranchMainS1P3Img" />
        </div>
      </div>
    </>
  );
}
function BranchMainS2() {
  return (
    <>
      <div className="BranchMainS2">
        <BigCart
          title={kellerBigCartData.title}
          subtitle={kellerBigCartData.subtitle}
          serviceTitle={kellerBigCartData.serviceTitle}
          price={kellerBigCartData.price}
          leftItems={kellerBigCartData.leftItems}
          rightItems={kellerBigCartData.rightItems}
          imageSrc={kellerBigCartData.imageSrc}
          imageAlt={kellerBigCartData.imageAlt}
        />
      </div>
      {/*  */}
      {/* Big Cards For Mob */}
      {/*  */}
      <div className="BranchMainS2Mob">
        <BigCartMob
          title={kellerBigCartData.title}
          subtitle={kellerBigCartData.subtitle}
          serviceTitle={kellerBigCartData.serviceTitle}
          price={kellerBigCartData.price}
          leftItems={kellerBigCartData.leftItems}
          rightItems={kellerBigCartData.rightItems}
          imageSrcMob={kellerBigCartData.imageSrcMob}
          imageAlt={kellerBigCartData.imageAlt}
        />
      </div>
    </>
  );
}
function BranchMainS3() {
  return (
    <>
      <div className="BranchMainS3">
        {/* Small Cards For Web */}
        <div className="BranchMainS3P1">
          {kellerSmallCartsData.map((cartData, index) => (
            <SmallCart
              key={index}
              serviceTitle={cartData.serviceTitle}
              price={cartData.price}
              leftItems={cartData.leftItems}
              imageSrc={cartData.imageSrc}
              imageAlt={cartData.imageAlt}
              height={cartData.height}
            />
          ))}
        </div>
        {/*  */}
        {/*Small Cards  For Mob */}
        <div className="BranchMainS3P1Mob">
          {kellerSmallCartsData.map((cartData, index) => (
            <SmallCartMob
              key={index}
              serviceTitle={cartData.serviceTitle}
              price={cartData.price}
              leftItems={cartData.leftItems}
              imageSrc={cartData.imageSrc}
              imageAlt={cartData.imageAlt}
              height={cartData.height}
              heightMob={cartData.heightMob}
            />
          ))}
        </div>
        {/*  */}
        <div className="BranchesGetButtonMain">
          <Link to="/Locations">
            <button className="BranchesGetButton">
              <img src={ButtonIcon} alt="" className="BranchesGetButtonImg" />
              Get Direction
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}

const images = [
  { url: KellerSlider1, alt: "Keller automatic car wash" },
  { url: KellerSlider2, alt: "Keller car cleaner bay" },
  { url: KellerSlider3, alt: "Keller car cleaner" },
  { url: KellerSlider4, alt: "Keller car wash bay" },
  { url: KellerSlider5, alt: "Keller car wash center" },
  { url: KellerSlider6, alt: "Keller family car wash" },
  { url: KellerSlider7, alt: "Keller self car wash" },
  { url: KellerSlider8, alt: "Keller touch free car wash" },
  { url: KellerSlider9, alt: "Keller wash car center" },
  { url: KellerSlider10, alt: "Keller wash my car" },
  { url: KellerSlider11, alt: "Keller washy car bay" },
  { url: KellerSlider12, alt: "Keller washy car service" },
];

function BranchMainS4() {
  return (
    <>
      <SliderBranches title="Keller location Photos" subtitle="more than just a wash" images={images} />
    </>
  );
}

function BranchMainS5() {
  return (
    <>
      <div className="BranchMainS5">
        <div className="BranchMainS5P1">Other location</div>
        <div className="BranchMainS5P2">
          <DisplayBranches
            images={Grand_Prairie}
            Alt="Grand Praire car wash service"
            BranchName="grand prairie"
            Url={`/GrandPrairie`}
            ButtonTitle="Grand Prairie Services"
          />
          <DisplayBranches images={Grapevine} Alt="Grapevine car wash service" BranchName="Grapevine" Url={`/Grapevine`} ButtonTitle="Grapevine Services" />
        </div>
      </div>
    </>
  );
}
