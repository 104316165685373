import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./NotFound.css";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="NotFoundC">
      <h1 className="NotFoundCT1">Page Not Found</h1>
      <p className="NotFoundCT2">Oops! The page you are looking for does not exist.</p>
      <div className="NotFoundCP2">
        <button onClick={() => navigate(-1)} className="NotFoundCB HomeS3P2B1ButtonTop">
          Go Back
        </button>
        <Link to="/" className="NotFoundCB HomeS3P2B1ButtonTop">
          Go to Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
