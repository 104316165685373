import React from "react";
import "./Reviews.css";
import HomeS3FirstWeb from "../../Assets/images/Reviews/GrandPrairie.webp";
import HomeS3FirstMob from "../../Assets/images/Home/HomeS3FirstMob.webp";
import ReviewsGoogle from "../../Assets/svg/Reviews/ReviewsGoogle.svg";
import ReviewsYelp from "../../Assets/svg/Reviews/ReviewsYelp.svg";
import { useIsMobile } from "../../Assets/hooks/IsMobile/IsMobile";
export default function GrandPrairieReviews() {
  return (
    <>
      <div className="Reviews">
        <RS1 />
      </div>
    </>
  );
}

function RS1() {
  const isMobile = useIsMobile();

  // For WebView

  return (
    <>
      <div className="RS1">
        <div className="RS1P1">
          <div className="RS1P1T1">Reviews</div>
          <div className="RS1P1T2">
            Share your thoughts!
            <br /> and we'll make sure your ride shines.
          </div>
        </div>
        {/*  */}
        <div className="RS1P2">
          {/*  */}
          <div className="RS1P2B1">
            <img src={isMobile ? HomeS3FirstMob : HomeS3FirstWeb} alt="gpl self wash car" className="RS1P2B1Img" />
            <h1 className="RS1P2B1H1">grand prairie</h1>
            <button className="RS1P2B1H2">NEW LOCATION</button>
            {/*  */}
            <div className="RS1P2B1V">
              <div className="RS1P1T2 RS1P2B1V1">REVIEW Grand Prairie on</div>
              <div className="RS1P2B1V2">
                <a href="https://g.page/r/CbYKYpjsrYP-EAI/review" target="Blank">
                  <img src={ReviewsGoogle} alt="" />
                </a>
                <a
                  href="https://www.yelp.com/writeareview/biz/Q1ybQ0rAEQd8ixDZAqoCPQ?return_url=%2Fbiz%2FQ1ybQ0rAEQd8ixDZAqoCPQ&review_origin=biz-details-war-button"
                  target="blank"
                >
                  <img src={ReviewsYelp} alt="" />
                </a>
              </div>
            </div>
            {/*  */}
          </div>
          {/*  */}
        </div>
      </div>
    </>
  );
}
