import React from "react";
import "./Reviews.css";
import HomeS3ThirdWeb from "../../Assets/images/Reviews/Keller.webp";
import HomeS3ThirdMob from "../../Assets/images/Home/HomeS3ThirdMob.webp";
import ReviewsGoogle from "../../Assets/svg/Reviews/ReviewsGoogle.svg";
import ReviewsYelp from "../../Assets/svg/Reviews/ReviewsYelp.svg";
import { useIsMobile } from "../../Assets/hooks/IsMobile/IsMobile";
export default function KellerReviews() {
  return (
    <>
      <div className="Reviews">
        <RS1 />
      </div>
    </>
  );
}

function RS1() {
  const isMobile = useIsMobile();

  return (
    <>
      <div className="RS1">
        <div className="RS1P1">
          <div className="RS1P1T1">Reviews</div>
          <div className="RS1P1T2">
            Share your thoughts!
            <br /> and we'll make sure your ride shines.
          </div>
        </div>
        {/*  */}
        <div className="RS1P2">
          {/*  */}
          <div className="RS1P2B1">
            <img src={isMobile ? HomeS3ThirdMob : HomeS3ThirdWeb} alt="Klrl self wash car" className="RS1P2B1Img" />
            <h1 className="RS1P2B1H1">Keller</h1>
            {/*  */}
            <div className="RS1P2B1V">
              <div className="RS1P1T2 RS1P2B1V1">REVIEW Keller on</div>
              <div className="RS1P2B1V2">
                <a href="https://g.page/r/Ca6U9ne2LTPyEAE/review" target="blank">
                  <img src={ReviewsGoogle} alt="" />
                </a>
                <a
                  href="https://www.yelp.com/writeareview/biz/7e2Ys_Y40w3ahE4G_CpD0Q?return_url=%2Fbiz%2F7e2Ys_Y40w3ahE4G_CpD0Q&review_origin=biz-details-war-button"
                  target="blank"
                >
                  <img src={ReviewsYelp} alt="" />
                </a>
              </div>
            </div>
            {/*  */}
          </div>
          {/*  */}
        </div>
      </div>
    </>
  );
}
