import React from "react";
import "../Branches.css";
import BigCart from "../../../Component/Branches/BigCart/BigCart";
import GrapevineBigMain from "../../../Assets/images/Branches/Grapevine/GrapevineBigMain.webp";
import SmallCart from "../../../Component/Branches/SmallCart/SmallCart";
import { Link } from "react-router-dom";
import ButtonIcon from "../../../Assets/svg/Header/Button_Icon.svg";
import SliderBranches from "../../../Component/Branches/SliderBranches/SliderBranches";
import DisplayBranches from "../../../Component/Branches/DisplayBranches/DisplayBranches";
import Grand_Prairie from "../../../Assets/images/Branches/Grand_Prairie.webp";
import Keller from "../../../Assets/images/Branches/Keller.webp";
import GrapevineSlider1 from "../../../Assets/images/Branches/Grapevine/GrapevineSlider1.webp";
import GrapevineSlider2 from "../../../Assets/images/Branches/Grapevine/GrapevineSlider2.webp";
import GrapevineSlider3 from "../../../Assets/images/Branches/Grapevine/GrapevineSlider3.webp";
import GrapevineSlider4 from "../../../Assets/images/Branches/Grapevine/GrapevineSlider4.webp";
import GrapevineSlider5 from "../../../Assets/images/Branches/Grapevine/GrapevineSlider5.webp";
import GrapevineSlider6 from "../../../Assets/images/Branches/Grapevine/GrapevineSlider6.webp";
import GrapevineSlider7 from "../../../Assets/images/Branches/Grapevine/GrapevineSlider7.webp";
import GrapevineSlider8 from "../../../Assets/images/Branches/Grapevine/GrapevineSlider8.webp";
import GrapevineSlider9 from "../../../Assets/images/Branches/Grapevine/GrapevineSlider9.webp";
import GrapevineSlider10 from "../../../Assets/images/Branches/Grapevine/GrapevineSlider10.webp";
import GrapevineSlider11 from "../../../Assets/images/Branches/Grapevine/GrapevineSlider11.webp";
import GrapevineSlider12 from "../../../Assets/images/Branches/Grapevine/GrapevineSlider12.webp";
import { grapevineBigCartData } from "../ServicesData/GrapevineData";
import { grapevineSmallCartsData } from "../ServicesData/GrapevineData";
import BigCartMob from "../../../Component/Branches/BigCart/BigCartMob";
import SmallCartMob from "../../../Component/Branches/SmallCart/SmallCartMob";
export default function GrapevineContent() {
  return (
    <>
      <div className="BranchMainContainer">
        <BranchMainS1 />
        <BranchMainS2 />
        <BranchMainS3 />
        <BranchMainS4 />
        <BranchMainS5 />
      </div>
    </>
  );
}

function BranchMainS1() {
  return (
    <>
      <div className="BranchMainS1">
        <div className="BranchMainS1P1">Welcome to Our </div>
        <div className="BranchMainS1P2">Grapevine Location </div>
        <div className="BranchMainS1P3">
          <img src={GrapevineBigMain} alt="Grapevine car wash center" className="BranchMainS1P3Img" />
        </div>
      </div>
    </>
  );
}
function BranchMainS2() {
  return (
    <>
      <div className="BranchMainS2">
        <BigCart
          title={grapevineBigCartData.title}
          subtitle={grapevineBigCartData.subtitle}
          serviceTitle={grapevineBigCartData.serviceTitle}
          price={grapevineBigCartData.price}
          leftItems={grapevineBigCartData.leftItems}
          rightItems={grapevineBigCartData.rightItems}
          imageSrc={grapevineBigCartData.imageSrc}
          imageAlt={grapevineBigCartData.imageAlt}
        />
      </div>
      {/*  */}
      {/* Big Cards For Mob */}
      {/*  */}
      <div className="BranchMainS2Mob">
        <BigCartMob
          title={grapevineBigCartData.title}
          subtitle={grapevineBigCartData.subtitle}
          serviceTitle={grapevineBigCartData.serviceTitle}
          price={grapevineBigCartData.price}
          leftItems={grapevineBigCartData.leftItems}
          rightItems={grapevineBigCartData.rightItems}
          imageSrcMob={grapevineBigCartData.imageSrcMob}
          imageAlt={grapevineBigCartData.imageAlt}
        />
      </div>
    </>
  );
}
function BranchMainS3() {
  return (
    <>
      <div className="BranchMainS3">
        {/* Small Cards For Web */}
        <div className="BranchMainS3P1">
          {grapevineSmallCartsData.map((cartData, index) => (
            <SmallCart
              key={index}
              serviceTitle={cartData.serviceTitle}
              price={cartData.price}
              leftItems={cartData.leftItems}
              imageSrc={cartData.imageSrc}
              imageAlt={cartData.imageAlt}
              height={cartData.height}
            />
          ))}
        </div>
        {/*  */}
        {/*Small Cards  For Mob */}
        <div className="BranchMainS3P1Mob">
          {grapevineSmallCartsData.map((cartData, index) => (
            <SmallCartMob
              key={index}
              serviceTitle={cartData.serviceTitle}
              price={cartData.price}
              leftItems={cartData.leftItems}
              imageSrc={cartData.imageSrc}
              imageAlt={cartData.imageAlt}
              height={cartData.height}
              heightMob={cartData.heightMob}
            />
          ))}
        </div>
        {/*  */}
        <div className="BranchesGetButtonMain">
          <Link to="/Locations">
            <button className="BranchesGetButton">
              <img src={ButtonIcon} alt="" className="BranchesGetButtonImg" />
              Get Direction
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}

const images = [
  { url: GrapevineSlider1, alt: "Grapevine automatic car wash" },
  { url: GrapevineSlider2, alt: "Grapevine car wash services" },
  { url: GrapevineSlider3, alt: "Grapevine care care center" },
  { url: GrapevineSlider4, alt: "Grapevine do it your self" },
  { url: GrapevineSlider5, alt: "Grapevine pat bay" },
  { url: GrapevineSlider6, alt: "Grapevine pet care pet wash" },
  { url: GrapevineSlider7, alt: "Grapevine pet wash pet care" },
  { url: GrapevineSlider8, alt: "Grapevine self service car wash bays" },
  { url: GrapevineSlider9, alt: "Grapevine self services wash" },
  { url: GrapevineSlider10, alt: "Grapevine touch free car wash" },
  { url: GrapevineSlider11, alt: "Grapevine wash cars bay" },
  { url: GrapevineSlider12, alt: "Grapevine yacht wash cleaning" },
];

function BranchMainS4() {
  return (
    <>
      <SliderBranches title="Grapevine location Photos" subtitle="always shining always clean" images={images} />
    </>
  );
}

function BranchMainS5() {
  return (
    <>
      <div className="BranchMainS5">
        <div className="BranchMainS5P1">Other location</div>
        <div className="BranchMainS5P2">
          <DisplayBranches
            images={Grand_Prairie}
            Alt="Grand Praire car wash service"
            BranchName="grand prairie"
            Url={`/GrandPrairie`}
            ButtonTitle="Grand Prairie Services"
          />
          <DisplayBranches images={Keller} Alt="Keller car wash service" BranchName="Keller" Url={`/Keller`} ButtonTitle="Keller Services" />
        </div>
      </div>
    </>
  );
}
