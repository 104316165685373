import React, { useState, useEffect } from "react";
import "./About.css";
import { Link } from "react-router-dom";
import AboutS1Img1 from "../../Assets/images/About/AboutS1Img1.webp";
import AboutS1Img2 from "../../Assets/images/About/AboutS1Img2.webp";
import AboutS1Img3 from "../../Assets/images/About/AboutS1Img3.webp";
import AboutS2ImgMob from "../../Assets/images/About/AboutS2ImgMob.webp";
import AboutS2ReImgMob from "../../Assets/images/About/AboutS2ReImgMob.webp";
import AboutS4Img1 from "../../Assets/images/About/AboutS4Img1.webp";
import AboutS4Img2 from "../../Assets/images/About/AboutS4Img2.webp";
import AboutRight from "../../Assets/svg/Home/HomeS3Right.svg";

export default function AboutUs() {
  return (
    <>
      <div className="AboutContainer">
        <AboutS1 />
        <AboutS2 />
        <AboutS3 />
        <AboutS4 />
        <AboutS5 />
      </div>
    </>
  );
}

// about s1
function AboutS1() {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [AboutS1Img1, AboutS1Img2, AboutS1Img3];

  const altTexts = ["abt self service car wash", "abt car wash self service", "abt car wash center"];

  useEffect(() => {
    const intervalRef = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 2500);

    return () => clearInterval(intervalRef);
  }, []);

  return (
    <>
      <div className="AboutS1">
        <div className="AboutS1P1">
          <div className="AboutS1P1V1">About Wash Your Stuff</div>
          <div className="AboutS1P1V2">A Family-Owned Tradition</div>
          <div className="AboutS1P2">
            <img src={images[currentImage]} alt={altTexts[currentImage]} className="AboutS1P2Img fade" />
          </div>
          <div className="AboutS1P1VLS"></div>
          <div className="AboutS1P1VHL">Our History</div>
          <div className="AboutS1P1V3">
            The self-service car wash has a rich history rooted in American innovation<span className="SpanBigger">,</span> beginning with the vision of Jack M.
            Thompson and Travis Harris. <br /> In 1962<span className="SpanBigger">,</span> these two pioneers launched the world’s first coin-operated car wash
            on Thanksgiving Day in Ardmore<span className="SpanBigger">,</span> Oklahoma<span className="SpanBigger">,</span> offering drivers an easy
            <span className="SpanBigger">,</span> accessible way to clean their vehicles. This invention forever changed the car care industry
            <span className="SpanBigger">,</span> sparking a movement that encouraged people to take care of their vehicles themselves.
          </div>
        </div>
      </div>
    </>
  );
}
// about s2
function AboutS2() {
  return (
    <>
      <div className="AboutS2">
        <div className="AboutS2P1">
          <div className="AboutS1P2">
            <img src={AboutS2ImgMob} alt="wys history 1 M" className="AboutS1P2Img fade" />
          </div>
          <div className="AboutS1P1V3">
            However<span className="SpanBigger">,</span> the push for automated convenience began even earlier<span className="SpanBigger">,</span> with the
            Anderson brothers introducing the world’s first fully-automated car wash in 1951 in Seattle<span className="SpanBigger">,</span> Washington. <br />{" "}
            This groundbreaking system provided the foundation for the car wash technology we know and use today—whether self-service
            <span className="SpanBigger">,</span> fully-automated<span className="SpanBigger">,</span> or touch-free.
          </div>
        </div>
      </div>
    </>
  );
}

function AboutS3() {
  return (
    <>
      <div className="AboutS1">
        <div className="AboutS2P1">
          <div className="AboutS1P2">
            <img src={AboutS2ReImgMob} alt="wys history 2 M" className="AboutS1P2Img fade" />
          </div>
          <div className="AboutS1P1V3 AlCenter">
            At Wash Your Stuff<span className="SpanBigger">,</span> <br className="NoneBr" /> we’re proud to be part of this legacy. <br />
            Our family-owned business carries forward the spirit of these early innovations by providing the community with both traditional self-service
            options with most advanced technology and state-of-the-art touch-free automated solutions.
          </div>
        </div>
      </div>
    </>
  );
}
// about s3

// about s4
function AboutS4() {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [AboutS4Img1, AboutS4Img2];

  const altTexts = ["abtv car cleaning", "abtv car cleaning touch free"];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 3500);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <>
      <div className="AboutS3">
        <div className="AboutS3Com">
          <div className="AboutS3P2">
            <div className="AboutS3P2V1">Our vision</div>
            <div className="AboutS3P2V2">
              Expert care<span className="SpanBiggerMore">,</span> <br /> guaranteed results.
            </div>
            <div className="AboutS3P2V3">
              Wash Your Stuff is committed to offering a premium car wash experience that meets the unique needs of each customer. <br /> Our self-service bays
              are designed for car enthusiasts and those who love detailing their vehicles personally<span className="SpanBigger">,</span> allowing for a
              thorough<span className="SpanBigger">,</span> high-quality wash with advanced equipment. <br /> For those who prefer convenience without
              compromising on quality<span className="SpanBigger">,</span> our touch-free automatic car wash provides a gentle, thorough clean without risking
              scratches or damage to the paint finish. <br />
              <br />
              Every wash option at Wash Your Stuff prioritizes care for both your vehicle and your experience. We aim to deliver the most satisfying and
              efficient car wash experience available<span className="SpanBigger">,</span> helping you keep your vehicle looking its best with confidence and
              ease.
            </div>
          </div>
          <div className="AboutS3P1">
            <img src={images[currentImage]} alt={altTexts[currentImage]} className="AboutS3P1Img" />
          </div>
        </div>
      </div>
      {/* For Mob */}
      <div className="AboutS3 AboutS3Mob">
        <div className="AboutS3Com">
          <div className="AboutS3P2">
            <div className="AboutS3P2V1">Our vision</div>
            <div className="AboutS3P2V2">
              Expert care<span className="SpanBiggerMore">,</span> guaranteed results.
            </div>
            <div className="AboutS3P1">
              <img src={images[currentImage]} alt={altTexts[currentImage]} className="AboutS3P1Img" />
            </div>
            <div className="AboutS3P2V3">
              At Wash Your Stuff<span className="SpanBigger">,</span> our vision is to revolutionize the car wash experience by pairing self-service with the
              highest level of technology in the industry! By delivering state of the art self-service car wash bays<span className="SpanBigger">,</span>
              touch-free automatic equipment, pet-wash bays*<span className="SpanBigger">,</span> and even a pet park*<span className="SpanBigger">,</span>Our
              goal is to set the benchmark for quality and convenience, providing a personalized experience tailored to each customer’s unique needs.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
// about s5
function AboutS5() {
  return (
    <>
      <div className="AboutS5">
        <div className="AboutS5P1">Join Our Wash Club and enjoy exclusive savings </div>
        <div className="AboutS5P2">
          Unlock exclusive savings with <br /> Coinless app Wash Club
        </div>
        <div className="AboutS5P3">
          <Link to="/WashClub">
            <button>
              Double Your Money Now <img src={AboutRight} alt="" />
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}
