import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./WashClub.css";
import WashClubS1Img from "../../Assets/svg/WashClub/WashClubS1Img.svg";
import WashClubQr from "../../Assets/images/WashClub/WashClubS3QR.png";
import WashClubPlayStore from "../../Assets/svg/WashClub/WashClubS3PlayStore.svg";
import WashClubAppStore from "../../Assets/svg/WashClub/WashClubS3AppStore.svg";
import HomeS3Right from "../../Assets/svg/Home/HomeS3Right.svg";
import WCS2Main from "../../Assets/svg/WashClub/WC_S2_Main.png";
import WCS2Mob from "../../Assets/svg/WashClub/WC_S2_Mob.svg";
import WCS2Heart from "../../Assets/svg/WashClub/WC_S2_Heart.svg";
import WCS2Star from "../../Assets/svg/WashClub/WC_S2_Star.svg";
export default function WashClubContent() {
  return (
    <>
      <div className="WashClubMain">
        <WashClubS1 />
        <WashClubS2New />
        <WashClubS2 />
        <WashClubS3 />
        <WashClubS4 />
      </div>
    </>
  );
}

function WashClubS1() {
  return (
    <>
      <div className="WashClubS1">
        <div className="WashClubS1P1">Wash Club</div>
        <div className="WashClubS1P2">
          <img src={WashClubS1Img} alt="" className="WashClubS1P2Img" />
        </div>
        <div className="WashClubS1P3">
          Wash More<span className="SpanBiggerMore">,</span> Save More!
        </div>
        <div className="WashClubS1P4">Love keeping your car clean but hate the cost? Join our Wash Club and save big on every wash! Here's how it works:</div>
      </div>
    </>
  );
}

function WashClubS2New() {
  return (
    <>
      <div className="WashClubS2New">
        <div className="WCS2NP1">
          <img src={WCS2Main} alt="" className="WCS2NP1Img" />
        </div>
        {/*  */}
        <div className="WCS2NP2">
          {/* Box 1 */}
          <div className="WCS2NP2V">
            {/*  */}
            <div className="WCS2NP2VF">
              <div>
                <img src={WCS2Mob} alt="" className="WCS2NP2VFImg" />
              </div>
              <div className="WCS2NP2VFT">Mobile Payments :</div>
            </div>
            <div className="WCS2NP2VT">
              Your phone becomes our pay station! <br /> Process payments through the app without needing to pull out your CC <br /> or rolling down your window
              when using our Touch-Free Automatic.
            </div>
            {/*  */}
          </div>
          {/* Box 2 */}
          <div className="WCS2NP2V">
            {/*  */}
            <div className="WCS2NP2VF">
              <div>
                <img src={WCS2Heart} alt="" className="WCS2NP2VFImg" />
              </div>
              <div className="WCS2NP2VFT">Loyalty Program :</div>
            </div>
            <div className="WCS2NP2VT">
              Get 1 point for every $1 you spend! <br /> You will get a free $5 credit after every $50 spent over time..
            </div>
            {/*  */}
          </div>
          {/* Box 3 */}
          <div className="WCS2NP2V">
            {/*  */}
            <div className="WCS2NP2VF">
              <div>
                <img src={WCS2Star} alt="" className="WCS2NP2VFImg" />
              </div>
              <div className="WCS2NP2VFT">Wash Club :</div>
            </div>
            <div className="WCS2NP2VT">
              Double your money by becoming a Wash Club member! <br /> Pay $20 and get $40, pay $30 and get $60, etc.
            </div>
            {/*  */}
          </div>
          {/*  */}
        </div>
        {/*  */}
      </div>
    </>
  );
}

function WashClubS2() {
  return (
    <>
      <div className="WashClubS2">
        {/*  */}
        <div className="WashClubS2Part">
          <div className="WashClubS2PartV1">Never Miss a Reward</div>
          <div className="WashClubS2PartV2">
            Earn points with every wash<span className="SpanBigger">,</span> adding up to even more savings!
          </div>
        </div>
        {/*  */}
        <div className="WashClubS2Part">
          <div className="WashClubS2PartV1">Double Your Money</div>
          <div className="WashClubS2PartV2">Pay just $20 per month and get $40 worth of credits to use at all locations.</div>
        </div>
        {/*  */}
        <div className="WashClubS2Part">
          <div className="WashClubS2PartV1">At Your Fingertips</div>
          <div className="WashClubS2PartV2">
            Download the Coinless app by visiting <br />
            <a href="https://www.coinlessdownload.com" target="blank">
              www.coinlessdownload.com
            </a>
          </div>
        </div>
        {/*  */}
      </div>
    </>
  );
}
function WashClubS3() {
  return (
    <>
      <div className="WashClubS3">
        <div className="WashClubS3P1">
          {" "}
          <img src={WashClubQr} alt="" className="WashClubS3P1Img" />
        </div>
        <div className="WashClubS3P2">
          <a href="https://play.google.com/store/apps/details?id=com.coinless" target="blank">
            <img src={WashClubPlayStore} alt="" className="WashClubS3P2Img" />
          </a>
          <a href="https://apps.apple.com/eg/app/coinless-mobile/id1363164643" target="blank">
            <img src={WashClubAppStore} alt="" className="WashClubS3P2Img" />
          </a>
        </div>
      </div>
    </>
  );
}
function WashClubS4() {
  return (
    <>
      <div className="WashClubS4">
        <div className="WashClubS4P1">our fleet club</div>
        {/*  */}
        <div className="WashClubS4P2">Does your business have a fleet of vehicles ? </div>
        {/*  */}
        <div className="WashClubS4P3">
          <a href="sms:817-380-8460">
            <div className="WashClubS4P3B">
              Text us <img src={HomeS3Right} alt="" />{" "}
            </div>
          </a>
        </div>
        {/*  */}
        {/* <div className="WashClubS4P4">
          <a href="sms:817-380-8460">817-380-8460</a>
        </div> */}
        {/*  */}
        <div className="WashClubS4P5">We offer fleet discounts</div>
      </div>
    </>
  );
}
