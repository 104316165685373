import React from "react";
import "./Terms_Conditions.css";

export default function TermsConditions() {
  return (
    <div className="TermsConditionsC">
      <PRS1 />
    </div>
  );
}

function PRS1() {
  return (
    <>
      <div className="PRS2">
        {/*  */}
        <div className="PRS2P1">
          <div className="PRS2P1T1">Terms and Conditions And Privacy Policy</div>
          {/* Terms and Conditions */}
          <PRBox
            Para={
              <>
                <div className="TCHL">Terms and Conditions:</div>
                {/* 1 */}
                <TCHLC
                  Head={<>1. Acceptance of Terms:</>}
                  Title={
                    <>
                      {" "}
                      By providing your name, email address, and phone number through our website's forms, or newsletter subscription, you agree to these Terms
                      and Conditions.
                    </>
                  }
                />
                {/* 2 */}
                <TCHLC
                  Head={<>2. Use of Your Information:</>}
                  Title={<>You agree that we may use your provided information to:</>}
                  li={[
                    <>Send you updates about new car wash locations.</>,
                    <>Send you newsletters containing car wash tips, news, and information.</>,
                    <>Send you marketing promotions and special offers related to our car wash services.</>,
                    <>Contact you regarding your inquiries or feedback.</>,
                  ]}
                />
                {/* 3 */}
                <TCHLC
                  Head={<>3. Communications:</>}
                  Title={<>You consent to receive communications from us via email and/or text regarding our services and promotions.</>}
                />
                {/* 4 */}
                <TCHLC
                  Head={<>4. Opt-Out:</>}
                  Title={
                    <>
                      You may opt-out of receiving communications at any time by clicking the "unsubscribe" link in our emails or by contacting us directly via
                      email at <a href="mailto:Support@washyourstuff.com">[Support@washyourstuff.com]</a> or via text to <span></span>
                      <a href="sms:(+1)(817-380-8460)">[817-380-8460]</a>.
                    </>
                  }
                />
                {/* 5 */}
                <TCHLC Head={<>5. Accuracy of Information:</>} Title={<>You guarantee that the information you provide is accurate and up-to-date.</>} />
                {/* 6 */}
                <TCHLC
                  Head={<>6. Limitation of Liability:</>}
                  Title={<>We are not liable for any damage arising from your use of our website or communications.</>}
                />
                {/* 7 */}
                <TCHLC
                  Head={<>7. Changes to Terms:</>}
                  Title={<>We reserve the right to modify these Terms and Conditions at any time. Changes will be sent to your provided email.</>}
                />
                {/* 8 */}
                <TCHLC
                  Head={<>8. Contact info.:</>}
                  Title={
                    <>
                      For any questions, please reach out via email at <a href="mailto:Support@washyourstuff.com">[Support@washyourstuff.com]</a> or phone at
                      <span> </span>
                      <a href="sms:(+1)(817-380-8460)">[817-380-8460]</a>.
                    </>
                  }
                />
                {/*  */}
                {/*  */}
                {/* Privacy Policy */}
                <div className="TCHL TCHLPP">Privacy Policy:</div>
                {/* 1 */}
                <TCHLC
                  Head={<>1. Information Collection:</>}
                  Title={
                    <>
                      We at Wash Your Stuff want you to know and understand that your privacy is important to us. This policy explains how we collect the
                      following personal information when you voluntarily submit an email through our website’s forms or subscribe to our newsletter:
                    </>
                  }
                  li={[<>Name.</>, <>Email address.</>, <>Phone number.</>]}
                />
                {/* 2 */}
                <TCHLC
                  Head={<>2. Use of Information:</>}
                  Title={<>We use your personal information for the following purposes:</>}
                  li={[
                    <>Send you newsletters and promotional materials.</>,
                    <>To improve our services and customers communication.</>,
                    <>To provide you with requested information or services.</>,
                    <>To respond to your inquiries.</>,
                    <>To send you updates about new car wash locations.</>,
                  ]}
                />
                {/* 3 */}
                <TCHLC
                  Head={<>3. Data Security:</>}
                  Title={
                    <>
                      We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no internet
                      transmission is entirely secure.
                    </>
                  }
                />
                {/* 4 */}
                <TCHLC
                  Head={<>4. Third-Party Disclosure:</>}
                  Title={
                    <>
                      We will not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as required by law
                      or to trusted service providers who assist us in operating our website or conducting our business (provided that those parties agree to
                      keep this information confidential).
                    </>
                  }
                />
                {/* 5 */}
                <TCHLC
                  Head={<>5. Data Retention:</>}
                  Title={
                    <>
                      We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer
                      retention period is required or permitted by law.
                    </>
                  }
                />
                {/* 6 */}
                <TCHLC
                  Head={<>6. Your Rights:</>}
                  Title={<>You have the right to:</>}
                  li={[<>Opt-out of marketing communications.</>, <>Request the deletion of your personal information.</>]}
                />
                {/* 7 */}
                <TCHLC
                  Head={<>7. Cookies:</>}
                  Title={<>Our website may use cookies to enhance your experience. You can control cookies through your browser settings.</>}
                />
                {/* 8 */}
                <TCHLC
                  Head={<>8. Changes to Privacy Policy:</>}
                  Title={<>We reserve the right to modify this Privacy Policy at any time. Changes will be sent to your provided email.</>}
                />
                {/* 9 */}
                <TCHLC
                  Head={<>9. Contact Us:</>}
                  Title={
                    <>
                      If you have any questions, please contact us at via email at <a href="mailto:Support@washyourstuff.com">[Support@washyourstuff.com]</a> or
                      phone at <span></span>
                      <a href="sms:(+1)(817-380-8460)">[817-380-8460]</a>.
                    </>
                  }
                />
              </>
            }
          />
        </div>
      </div>
    </>
  );
}

function PRBox({ Para }) {
  return (
    <>
      <div className="PRBox">
        <div className="PRBoxT2">{Para}</div>
      </div>
    </>
  );
}
function TCHLC({ Head, Title, li = [] }) {
  return (
    <div className="TCHLC">
      <div className="TCHLCH">{Head}</div>
      <div className="TCHLCT">{Title}</div>
      {li.length > 0 && (
        <ul className="TCHLCUL">
          {li.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      )}
    </div>
  );
}
