import React, { useState, useRef, useEffect } from "react";
import "./FooterMob.css";
import FooterMobLogo from "../../Assets/svg/Header/LogoScroll.svg";
import ButtonIconRight from "../../Assets/svg/Home/HomeS3Right.svg";
import { Link } from "react-router-dom";
import FaceBook from "../../Assets/svg/Footer/FaceBook_Logo.svg";
import Instgram from "../../Assets/svg/Footer/Instgram_Logo.svg";
import TikTok from "../../Assets/svg/Footer/TikTok_Logo.svg";
import YouTube from "../../Assets/svg/Blog/YouTubeIcon.svg";
import axios from "axios";

export default function FooterMobile() {
  return (
    <>
      <div className="FooterMobMain">
        <FooterMobS1 />
        <FooterMobS2 />
        <FooterMobS3 />
      </div>
    </>
  );
}
function FooterMobS1() {
  const formRef = useRef();
  const [captcha, setCaptcha] = useState("");
  const [userCaptchaInput, setUserCaptchaInput] = useState("");
  const [isCaptchaOpen, setIsCaptchaOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    generateCaptcha();
  }, []);

  const generateCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    setCaptcha(`${num1} + ${num2}`);
  };

  const handleSubscribe = async () => {
    try {
      const response = await axios.post(
        "https://api.brevo.com/v3/contacts",
        {
          email: email,
          listIds: [2],
        },
        {
          headers: {
            "api-key": "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",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        setIsSuccess(true);
        setEmail("");
      } else {
        throw new Error("Subscription failed");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const errorCode = error.response.data.code;
        if (errorCode === "duplicate_parameter") {
          alert("You are already subscribed.");
        } else {
          alert("Failed to subscribe. Please check your input and try again.");
        }
      } else {
        console.error("Error during subscription:", error);
        alert("An unexpected error occurred. Please try again.");
      }
    }
  };

  const verifyCaptchaAndSubscribe = async () => {
    const captchaValues = captcha.split(" + ").map(Number);
    const correctCaptchaAnswer = captchaValues[0] + captchaValues[1];

    if (parseInt(userCaptchaInput) !== correctCaptchaAnswer) {
      alert("Captcha verification failed. Please try again.");
      return;
    }

    setIsLoading(true);
    try {
      await handleSubscribe();
    } catch (error) {
      console.error("Subscription failed:", error);
    } finally {
      setIsLoading(false);
      generateCaptcha();
      setUserCaptchaInput("");
    }
  };

  return (
    <>
      <div className="FooterMobS1">
        {/*  */}
        <div className="FooterMobS1P1">
          <img src={FooterMobLogo} alt="" />
        </div>
        {/*  */}
        <div className="FooterMobS1P2">
          <div className="FooterMobS1P2V1">Mailing list & offers</div>
          <form
            ref={formRef}
            onSubmit={(e) => {
              e.preventDefault();
              setIsCaptchaOpen(true);
            }}
            className="FooterMobS1P2"
          >
            <div className="FooterMobS1P2V2">
              <label className="Footer_Mob_input">
                <input className="Footer_Mob_Input" type="text" placeholder="E-mail *" value={email} onChange={(e) => setEmail(e.target.value)} required />
              </label>
            </div>
            <div className="FooterMobS1P2V3">
              <button className="HeaderMobButton" type="submit">
                Subscribe <img src={ButtonIconRight} alt="" className="HeaderMobButtonImg" />
              </button>
            </div>
          </form>
        </div>
        {/*  */}
      </div>

      {isCaptchaOpen && (
        <div className="captchaModal">
          <div className="captchaModalContent">
            {isLoading ? (
              <p>Loading...</p>
            ) : isSuccess ? (
              <>
                <p>Thanks For Subscription</p>
                <button
                  onClick={() => {
                    setIsCaptchaOpen(false);
                    window.location.reload();
                  }}
                >
                  Close
                </button>
              </>
            ) : (
              <>
                <h3>Verify you are human</h3>
                <p>What is {captcha}?</p>
                <input type="text" placeholder="Enter the answer" value={userCaptchaInput} onChange={(e) => setUserCaptchaInput(e.target.value)} required />
                <button onClick={verifyCaptchaAndSubscribe}>Verify and Send</button>
                <button
                  onClick={() => {
                    setIsCaptchaOpen(false);
                    setUserCaptchaInput("");
                    generateCaptcha();
                  }}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}
function FooterMobS2() {
  return (
    <>
      <div className="FooterMobS2">
        {/*  */}
        <div className="FooterMobS2P1">
          <div className="FooterMobS2P1V1">
            <div className="FooterMobS2P1V1T">Contact Us</div>
            <div className="FooterMobS2P1V1L">
              Phone <br />
              <a href="tel:+18173808460">(817) 380-8460</a>
            </div>
            <div className="FooterMobS2P1V1L FooterMobS2Email">
              Email <br />
              <a href="mailto:support@washyourstuff.com">support@washyourstuff.com</a>
            </div>
          </div>
          {/*  */}
          <div className="FooterMobS2P2V1">
            <div className="FooterMobS2P2V1T">Locations</div>
            <div className="FooterMobS2P2V1L">
              <Link to="/GrandPrairie">Grand Prairie Location</Link>
            </div>
            <div className="FooterMobS2P2V1L">
              <Link to="/Grapevine">Grapevine Location</Link>
            </div>
            <div className="FooterMobS2P2V1L">
              <Link to="/Keller">Keller Location</Link>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="FooterMobS2P2">
          <div className="FooterMobS2P2V1">
            Subscribe Now <br /> wash club
          </div>
          <div className="FooterMobS2P2V2">
            <Link to="/WashClub">
              <button className="HeaderMobButton">
                Learn More <img src={ButtonIconRight} alt="" className="HeaderMobButtonImg" />
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
function FooterMobS3() {
  return (
    <>
      <div className="FooterMobS3">
        <div className="FooterMobS3P2">
          <a href="https://www.tiktok.com/@washyourstuff?_t=8qwtvVHoy2y&_r=1" target="blank">
            <img src={TikTok} alt="" className="Footer_Soical_Img_Mob" />
          </a>
          <a href="https://www.facebook.com/washyourstuff" target="blank">
            <img src={FaceBook} alt="" className="Footer_Soical_Img_Mob" />
          </a>
          <a href="https://www.instagram.com/washyourstuff?igsh=MTBoZHVuYndtaDF2bQ==" target="blank">
            <img src={Instgram} alt="" className="Footer_Soical_Img_Mob" />
          </a>
          <a href="https://www.youtube.com/@washyourstuff" target="blank">
            <img src={YouTube} alt="" className="Footer_Soical_Img_Mob" />
          </a>
        </div>
        <div className="FooterMobS3P1">
          Copyright © 2024 Wash Your <br /> Stuff - All Rights Reserved.
        </div>
      </div>
    </>
  );
}
