import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import ScrollToTop from "./Component/Scrollup/ScrollUp";
import Home from "./Pages/Home/Home";
import AboutUs from "./Pages/About-us/About";
import Services from "./Pages/Service/Service";
import FaqsContent from "./Pages/Faqs/Faqs";
import BlogContent from "./Pages/Blog/Blog";
import ArticlePage1 from "./Pages/Article/ArticlePage1";
import ArticlePage2 from "./Pages/Article/ArticlePage2";
import ArticlePage3 from "./Pages/Article/ArticlePage3";
import HomeHeader from "./Component/HeaderHome/HeaderHomeWeb";
import Header from "./Component/Header/HeaderWeb";
import HeaderMobile from "./Component/Header/HeaderMobile";
import Footer from "./Component/Footer/FooterWeb";
import FooterMobile from "./Component/Footer/FooterMobile";
import KellerContent from "./Pages/Branch/Keller/Keller";
import GrapevineContent from "./Pages/Branch/Grapevine/Grapevine";
import GrandPrairieContent from "./Pages/Branch/Grand_Prairie/Grand_Prairie";
import WashClubContent from "./Pages/WashClub/WashClub";
import FeedBackContent from "./Pages/Feedback/FeedBack";
import ContactUsContent from "./Pages/Contact-us/ContactUs";
import LocationsNew from "./Pages/Location/LocationsNew";
import GrandPrairieReviews from "./Pages/Reviews/GrandPrairieReviews";
import KellerReviews from "./Pages/Reviews/KellerReviews";
import GrapevineReviews from "./Pages/Reviews/GrapevineReviews";
import PromotionsContent from "./Pages/Promotions/Promotions";
import TermsConditions from "./Pages/Terms_Conditions/Terms_Conditions";
import NotFound from "./Pages/404/NotFound";
import ReactGA from "react-ga4";

// Routes with titles
const routes = [
  { path: "/", component: <Home />, title: "Home - Wash Your Stuff" },
  { path: "/About-us", component: <AboutUs />, title: "About Us - Wash Your Stuff" },
  { path: "/Services", component: <Services />, title: "Services - Wash Your Stuff" },
  { path: "/faqs", component: <FaqsContent />, title: "FAQs - Wash Your Stuff" },
  { path: "/blog", component: <BlogContent />, title: "Blog - Wash Your Stuff" },
  { path: "/Contact-us", component: <ContactUsContent />, title: "Contact Us - Wash Your Stuff" },
  { path: "/Locations", component: <LocationsNew />, title: "Locations - Wash Your Stuff" },
  { path: "/whytouch-free", component: <ArticlePage1 />, title: "Why Touch-Free - Wash Your Stuff" },
  { path: "/A-Dog-Grooming", component: <ArticlePage2 />, title: "A Dog Grooming - Wash Your Stuff" },
  { path: "/great-car-wash", component: <ArticlePage3 />, title: "Great Car Wash - Wash Your Stuff" },
  { path: "/Keller", component: <KellerContent />, title: "Keller - Wash Your Stuff" },
  { path: "/Grapevine", component: <GrapevineContent />, title: "Grapevine - Wash Your Stuff" },
  { path: "/GrandPrairie", component: <GrandPrairieContent />, title: "Grand Prairie - Wash Your Stuff" },
  { path: "/WashClub", component: <WashClubContent />, title: "Wash Club - Wash Your Stuff" },
  { path: "/Feedback", component: <FeedBackContent />, title: "Feedback - Wash Your Stuff" },
  { path: "/Grand-Prairie-Reviews", component: <GrandPrairieReviews />, title: "Grand Prairie Reviews - Wash Your Stuff" },
  { path: "/Keller-Reviews", component: <KellerReviews />, title: "Keller Reviews - Wash Your Stuff" },
  { path: "/Grapevine-Reviews", component: <GrapevineReviews />, title: "Grapevine Reviews - Wash Your Stuff" },
  { path: "/Promotions", component: <PromotionsContent />, title: "Promotions - Wash Your Stuff" },
  { path: "/Terms-And-Conditions", component: <TermsConditions />, title: "Terms and Conditions - Wash Your Stuff" },
];

export default function RoutePages() {
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  // Google Analytics
  useEffect(() => {
    const timer = setTimeout(() => {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname + location.search,
        title: document.title,
      });
    }, 300);
    return () => clearTimeout(timer);
  }, [location]);

  // Set Page Titles
  useEffect(() => {
    const route = routes.find((r) => r.path === location.pathname);
    document.title = route ? route.title : "Wash Your Stuff";
  }, [location]);

  // Handle Mobile Screen
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Header Component
  const HeaderComponent = isHomePage ? (isSmallScreen ? HeaderMobile : HomeHeader) : isSmallScreen ? HeaderMobile : Header;

  // Footer Component
  const FooterComponent = isSmallScreen ? FooterMobile : Footer;

  return (
    <>
      <HeaderComponent />
      <ScrollToTop />
      <Routes>
        {routes.map(({ path, component }, index) => (
          <Route key={index} path={path} element={component} />
        ))}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <FooterComponent />
    </>
  );
}
