import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./Footer.css";
import Logo from "../../Assets/svg/Header/LogoScroll.svg";
import FaceBook from "../../Assets/svg/Footer/FaceBook_Logo.svg";
import Instgram from "../../Assets/svg/Footer/Instgram_Logo.svg";
import TikTok from "../../Assets/svg/Footer/TikTok_Logo.svg";
import YouTube from "../../Assets/svg/Blog/YouTubeIcon.svg";
import HomeS3Right from "../../Assets/svg/Home/HomeS3Right.svg";
export default function FooterWeb() {
  const formRef = useRef();
  const [captcha, setCaptcha] = useState("");
  const [userCaptchaInput, setUserCaptchaInput] = useState("");
  const [isCaptchaOpen, setIsCaptchaOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    generateCaptcha();
  }, []);

  const generateCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    setCaptcha(`${num1} + ${num2}`);
  };

  const handleSubscribe = async () => {
    try {
      const response = await axios.post(
        "https://api.brevo.com/v3/contacts",
        {
          email: email,
          listIds: [2],
        },
        {
          headers: {
            "api-key": "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",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        setIsSuccess(true);
        setEmail("");
      } else {
        throw new Error("Subscription failed");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const errorCode = error.response.data.code;
        if (errorCode === "duplicate_parameter") {
          alert("You are already subscribed.");
        } else {
          alert("Failed to subscribe. Please check your input and try again.");
        }
      } else {
        console.error("Error during subscription:", error);
        alert("An unexpected error occurred. Please try again.");
      }
    }
  };

  const verifyCaptchaAndSubscribe = async () => {
    const captchaValues = captcha.split(" + ").map(Number);
    const correctCaptchaAnswer = captchaValues[0] + captchaValues[1];

    if (parseInt(userCaptchaInput) !== correctCaptchaAnswer) {
      alert("Captcha verification failed. Please try again.");
      return;
    }

    setIsLoading(true);
    try {
      await handleSubscribe();
    } catch (error) {
      console.error("Subscription failed:", error);
    } finally {
      setIsLoading(false);
      generateCaptcha();
      setUserCaptchaInput("");
    }
  };

  return (
    <>
      <div className="FooterWebContainer">
        <div className="FooterWebMain">
          <div className="FooterWebMainS1">Mailing list & offers</div>
          <div className="FooterWebMainS2">
            <form
              ref={formRef}
              onSubmit={(e) => {
                e.preventDefault();
                setIsCaptchaOpen(true);
              }}
              className="FooterWebMainS2"
            >
              <label className="input">
                <input className="Footer_Input" type="text" placeholder="E-Mail *" value={email} onChange={(e) => setEmail(e.target.value)} required />
              </label>
              <button className="Footer_Main_Button" type="submit">
                Subscribe <img src={HomeS3Right} alt="" className="FooterButtonIcon" />
              </button>
            </form>
          </div>
        </div>

        {isCaptchaOpen && (
          <div className="captchaModal">
            <div className="captchaModalContent">
              {isLoading ? (
                <p>Loading...</p>
              ) : isSuccess ? (
                <>
                  <p>Thanks For Subscription</p>
                  <button
                    onClick={() => {
                      setIsCaptchaOpen(false);
                      window.location.reload();
                    }}
                  >
                    Close
                  </button>
                </>
              ) : (
                <>
                  <h3>Verify you are human</h3>
                  <p>What is {captcha}?</p>
                  <input type="text" placeholder="Enter the answer" value={userCaptchaInput} onChange={(e) => setUserCaptchaInput(e.target.value)} required />
                  <button onClick={verifyCaptchaAndSubscribe}>Verify and Send</button>
                  <button
                    onClick={() => {
                      setIsCaptchaOpen(false);
                      setUserCaptchaInput("");
                      generateCaptcha();
                    }}
                  >
                    Cancel
                  </button>
                </>
              )}
            </div>
          </div>
        )}
        {/*  */}
        <div className="FooterWebS2"></div>
        {/*  */}
        <div className="FooterWebS1">
          {/*  */}
          <div className="FooterWebS1V1">
            <h3>Pages</h3>
            <Link to="/">Home</Link>
            <Link to="About-us">About Us</Link>
            <Link to="/Services">Services</Link>
            <Link to="/FAQs">FAQs</Link>
            <Link to="/Blog">Blog</Link>
            <Link to="/Contact-us">Contact us</Link>
          </div>
          {/*  */}
          <div className="FooterWebS1V1">
            <h3>Contact Us</h3>
            <div className="PhoneNumberTD">
              Phone
              <a href="tel:+18173808460" className="">
                <br /> (817) 380-8460
              </a>
            </div>
            <a href="mailto:support@washyourstuff.com">
              Email <br /> support@washyourstuff.com
            </a>
          </div>
          {/*  */}
          <div className="FooterWebS1V1">
            <div className="FooterWebS1V1">
              <h3>Locations</h3>
              <Link to="/GrandPrairie">Grand Prairie Location</Link>
              <Link to="/Grapevine">Grapevine Location</Link>
              <Link to="/Keller">Keller Location</Link>
            </div>
          </div>
          {/*  */}
          <div className="FooterWebS1V1">
            <div className="FooterWebS1V2">
              <h3>
                Subscribe Now <br /> wash club
              </h3>
              <div className="FooterWebS1V2Button">
                <Link to="/WashClub" className="FooterWebS1V2Button">
                  <button className="Footer_Main_ButtonTall">
                    Learn More <img src={HomeS3Right} alt="" className="HomeS7P1V3Icon" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
          {/*  */}
          {/*  */}
        </div>
        {/*  */}
        <div className="FooterWebS2"></div>
        {/*  */}
        <div className="FooterWebS3">
          {/*  */}
          <div>
            <img src={Logo} alt="" className="FooterWebS3V1Img" />
          </div>
          {/*  */}
          <div className="FooterWebS3V2">Copyright © 2024 Wash Your Stuff - All Rights Reserved.</div>
          {/*  */}
          <div className="Footer_Soical">
            <a href="https://www.tiktok.com/@washyourstuff?_t=8qwtvVHoy2y&_r=1" target="blank">
              <img src={TikTok} alt="" className="Footer_Soical_Img" />
            </a>
            <a href="https://www.facebook.com/washyourstuff" target="blank">
              <img src={FaceBook} alt="" className="Footer_Soical_Img" />
            </a>
            <a href="https://www.instagram.com/washyourstuff?igsh=MTBoZHVuYndtaDF2bQ==" target="blank">
              <img src={Instgram} alt="" className="Footer_Soical_Img" />
            </a>
            <a href="https://www.youtube.com/@washyourstuff" target="blank">
              <img src={YouTube} alt="" className="Footer_Soical_Img" />
            </a>
          </div>
          {/*  */}
        </div>
      </div>
    </>
  );
}
