import React from "react";
import "./Faqs.css";
import Faqs from "../../Assets/hooks/Reuse_Component/Faqs/Faqs";
import { Link } from "react-router-dom";
import AboutRight from "../../Assets/svg/Home/HomeS3Right.svg";

export default function FaqsContent() {
  return (
    <>
      <div className="FaqsContainer">
        <FaqsS1 />
        <FaqsS2 />
        <FaqsS3 />
        <FaqsS4 />
        <FaqsS5 />
      </div>
    </>
  );
}
// faqs section 1
function FaqsS1() {
  return (
    <>
      <div className="FaqsS1">
        <div className="FaqsS1P1">Helpful Information</div>
        <div className="FaqsS1P2">Frequently asked questions</div>
        <div className="FaqsS1P3">Here Are Some Common Questions About Wash Your Stuff</div>
      </div>
    </>
  );
}
// faqs section 2

const faqDataS1 = [
  // First
  {
    question: "What are the working hours for all locations?",
    answer: (
      <>
        We Operate <span>24/7</span> In all our Locations. <br /> (Check our locations page for directions)
      </>
    ),
  },
  // Second
  {
    question: "What is the max height clearance for the self-wash bays?",
    answer: (
      <>
        Clearance varies in each Location<span className="SpanBigger">,</span> <br /> <span> Grand prairie: </span> Starting From <span>9’5”</span> Up To{" "}
        <span>12’10”</span> <br />
        <span> Grapevine:</span> Starting From <span>7’2</span>” Up To <span>13’5</span>” <br /> <span> Keller: </span> Starting From <span>7’2</span>” Up To{" "}
        <span>10’6</span>”
      </>
    ),
  },
  // Third
  {
    question: "Does your touch-free automatic car wash wash trucks?",
    answer: (
      <>
        Our touch-less automatic washes pickup trucks including dually trucks. <br /> In Keller and Grapevine we can wash vehicles up to <span>7’2”</span>.
        <br />
        Our touchless wash in Grand Prairie has the ability to wash vans and trucks up to <span>9’10” </span>tall. <br /> Please make sure there are no trailers
        or after market accessories that may affect if your vehicle would fit.
        <br />
        Please also check length and width restrictions displayed at the pay station of each location.
      </>
    ),
  },
  // Fourth
  {
    question: "Will a dually truck fit through the laser wash?",
    answer: (
      <>
        Yes<span className="SpanBigger">,</span> in ALL our touch free machines.
      </>
    ),
  },
  {
    question: "Is your automatic car wash safe for my new mini convertible?",
    answer: (
      <>
        YES<span className="SpanBigger">,</span> Our Touch-free machines is safe for almost all vehicles.
      </>
    ),
  },
  // Fourth
  {
    question: "Is your automatic car wash safe for carbon fiber?",
    answer: (
      <>
        Yes<span className="SpanBigger">,</span> both our touch free and self service equipment are safe for carbon <br /> fiber wrap providing it was done
        correctly.
      </>
    ),
  },
  {
    question: "Do you wash exotic cars?",
    answer: (
      <>
        Yes<span className="SpanBigger">,</span> our usually we have customers who come from far distances to wash <br /> their cars in our self service bays or
        touch free automatic machine.
      </>
    ),
  },
  {
    question: "What Vacuums you use?",
    answer: (
      <>
        Our Vacuums have excellent suction<span className="SpanBigger">,</span> and we have added Shampoo Vacuums. <br /> also we offer Fragrance
        Vacuums and Air Vacuums. <br /> (Check Services page for more info.)
      </>
    ),
  },
];
function FaqsS2() {
  return (
    <>
      <div className="FaqsS">
        <div className="FaqsSP1">About Bays & machines</div>
        <div className="FaqsSP2">
          <Faqs faqData={faqDataS1} />
        </div>
      </div>
    </>
  );
}
// faqs section 3
const faqDataS2 = [
  {
    question: "Does washing different items has different prices?",
    answer: (
      <>
        No<span className="SpanBigger">,</span>
        <br />
        <span>Self Service</span> wash is time based. <br /> So<span className="SpanBigger">,</span> You can wash any item and charged the same exact price per
        time unit.
      </>
    ),
  },
  {
    question: "Does every vehicle have different cost?",
    answer: (
      <>
        No<span className="SpanBigge">,</span>
        <br /> Self Service wash it is time based<span className="SpanBigger">,</span> It’s the same exact price per time unit. <br /> Touch-free Automatic wash
        is package based<span className="SpanBigger">,</span> Each package has a different price depending on the options. <br /> (Check Services Page to know
        more bout each branch services & prices)
      </>
    ),
  },
  {
    question: "Can I purchase gift cards?",
    answer: (
      <>
        Yes<span className="SpanBigger">,</span> Anyone can purchase prepaid credits & we can then invite a customer to Coinless app. <br /> When they register
        <span className="SpanBigger">, </span>
        they will find the prepaid credits.
        <br /> <br /> For example: Let's say you want to gift someone $50 in wash credits
        <span className="SpanBigger">,</span> We as a merchant can charge their card $50. <br /> You then can give us the gifted persons Name
        <span className="SpanBigger">,</span> email<span className="SpanBigger">,</span> and phone number and we can create a coinless app account for them and
        invite them to activate there gifted $50. <br /> (Check WASH CLUB page for more info.)
      </>
    ),
  },
  {
    question: "What Is Coinless App?",
    answer: (
      <>
        Coinless app Is a wash club application where you can use to double your washing money value. <br /> <br /> For example: when you pay $20 you get $40 of
        washing credits that can be used in all our locations. <br /> All you need to do is download the app & register. <br /> (Check WASH CLUB page for more
        info.)
      </>
    ),
  },
  {
    question: "Do you offer coupons, military discounts or veterans discounts?",
    answer: (
      <>
        Through our Coinless app we can give a discount to anyone. <br /> you just have to reach out to us via text. <br /> (Check WASH CLUB page for more
        info.)
      </>
    ),
  },
  {
    question: "Is your automatic car wash safe for my new mini convertible?",
    answer: "Yes, Our Touch-free machines will never harm your vehicle.",
  },
];
function FaqsS3() {
  return (
    <>
      <div className="FaqsS">
        <div className="FaqsSP1">About Pricing</div>
        <div className="FaqsSP2">
          <Faqs faqData={faqDataS2} />
        </div>
      </div>
    </>
  );
}
// faqs section 4
const faqDataS3 = [
  {
    question: "Where is the Pet wash located?",
    answer: (
      <>
        In Grapevine Branch<span className="SpanBigger">,</span> <br /> We provide Pet Wash Bays and a Pet Park<span className="SpanBigger">,</span> where you
        can pamper your furry friend with Shampoo, Conditioner<span className="SpanBigger">,</span> <br /> Odor Remover<span className="SpanBigger">,</span> Air
        Dryer and more. <br />
        (Check Services page for more info.)
      </>
    ),
  },
  {
    question: "Does the dog wash have warm water or cold water?",
    answer: "We use warm water.",
  },
  {
    question: "What sizes of dogs can I bathe at your place?",
    answer: (
      <>
        No size limit. <br /> Just Bring your furry friend and enjoy the washing.
      </>
    ),
  },
];
function FaqsS4() {
  return (
    <>
      <div className="FaqsS">
        <div className="FaqsSP1">About Pet wash & Pet Park</div>
        <div className="FaqsSP2">
          <Faqs faqData={faqDataS3} />
        </div>
      </div>
    </>
  );
}

// faqs section 5
function FaqsS5() {
  return (
    <>
      <div className="FaqsS5Container">
        <div className="FaqsS5">
          <div className="FaqsS5P1">Join Our Wash Club and enjoy exclusive savings </div>
          <div className="FaqsS5P2">
            Unlock exclusive savings with <br /> Coinless app Wash Club
          </div>
          <div className="FaqsS5P3">
            <Link to="/WashClub">
              <button>
                Double Your Money Now <img src={AboutRight} alt="" />
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
