import React, { useState, useRef, useEffect } from "react";
import "./Promotions.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import HomeS3Right from "../../Assets/svg/Home/HomeS3Right.svg";
import { Link } from "react-router-dom";
import HomeS3First from "../../Assets/images/Home/HomeS3First.webp";
import HomeS3Second from "../../Assets/images/Home/HomeS3Second.webp";
import HomeS3Third from "../../Assets/images/Home/HomeS3Third.webp";
import Select from "react-select";

export default function PromotionsContent() {
  return (
    <>
      <div className="PromotionsMain">
        <PromotionsS1 />
        <PromotionsS2 />
        <PromotionsS3 />
      </div>
    </>
  );
}

function PromotionsS1() {
  return (
    <>
      <div className="PromotionsS1">
        <div className="PromotionsS1P1">Promotions</div>
        <div className="PromotionsS1P2">
          Your journey to amazing wash <br />
          starts here!
        </div>
        <div className="PromotionsS1P4">
          Treat your car with a pampering session and enjoy that "just washed" feeling, Relax and let us take care of your car.
        </div>
        <div className="PromotionsS1P5">Wash Your Stuff family</div>
      </div>
    </>
  );
}

function PromotionsS2() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 575);
  const [errorMessage, setErrorMessage] = useState("");
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 575);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const customSelectStyles = {
    control: (base) => ({
      ...base,
      border: "none",
      borderRadius: "5px",
      padding: "0.5vw 1vw",
      fontSize: isMobile ? "3.5vw" : "1vw",
      backgroundColor: "#f0f3fa",
      fontFamily: "SanDiego",
      color: "#5b7a99",
      boxShadow: "none",
      width: isMobile ? "80vw" : "50vw",
      "&:hover": {
        borderColor: "#5b7a99",
      },
    }),
    placeholder: (base) => ({
      ...base,
      fontFamily: "SanDiego",
      fontSize: isMobile ? "3.5vw" : "1vw",
      color: "#5b7a99",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#5b7a99",
      "&:hover": {
        color: "#5b7a99",
      },
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: "5px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      overflow: "hidden",
      backgroundColor: "#f0f3fa",
      padding: "0.5vw 1vw",
      zIndex: 1000,
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? "#f0f3fa" : state.isFocused ? "#f0f3fa" : "#f0f3fa",
      color: "#333",
      padding: "10px",
      cursor: "pointer",
      fontFamily: "SanDiego",
      fontSize: isMobile ? "3.5vw" : "1vw",
      "&:hover": {
        backgroundColor: "#f0f3fa",
      },
    }),
  };

  const form = useRef();
  const [captcha, setCaptcha] = useState("");
  const [userCaptchaInput, setUserCaptchaInput] = useState("");
  const [isCaptchaOpen, setIsCaptchaOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [selectedPromotion, setSelectedPromotion] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  useEffect(() => {
    generateCaptcha();
  }, []);

  const generateCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    setCaptcha(`${num1} + ${num2}`);
  };

  const validateInputs = (formData) => {
    const name = formData.get("user_name").trim();
    const email = formData.get("user_email").trim();
    const phone = formData.get("user_phone").trim();

    if (name.length < 3 || !email.includes("@") || phone.length < 3) {
      alert("Please fill all fields correctly.");
      return false;
    }
    return true;
  };

  const handleTermsCheckboxChange = (event) => {
    setIsTermsAccepted(event.target.checked);
  };

  const handleSubscribeCheckboxChange = (event) => {
    setIsSubscribed(event.target.checked);
  };

  const sendEmailAfterVerification = async (e) => {
    e.preventDefault();
    console.log("Form submitted");

    const formData = new FormData(form.current);
    // Check if terms checkbox is checked
    if (!isTermsAccepted) {
      setErrorMessage("You must accept our Terms and Conditions and Privacy Policy");
      return;
    }

    if (!validateInputs(formData)) return;
    setErrorMessage(""); // Clear error message if terms are accepted
    setIsCaptchaOpen(true);
  };
  // create it at 11-3-25 to check the mail already in lists or not
  const checkEmailExists = async (email) => {
    try {
      const response = await axios.get(`https://api.brevo.com/v3/contacts/${encodeURIComponent(email)}`, {
        headers: {
          "api-key": "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",
        },
      });
      return response.status === 200;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return false;
      }
      console.error("Error checking email existence:", error);
      return null;
    }
  };

  const verifyCaptchaAndSendEmail = async () => {
    const captchaValues = captcha.split(" + ").map(Number);
    const correctCaptchaAnswer = captchaValues[0] + captchaValues[1];

    if (parseInt(userCaptchaInput) !== correctCaptchaAnswer) {
      alert("Captcha verification failed. Please try again.");
      setUserCaptchaInput("");
      generateCaptcha();
      return;
    }

    setIsLoading(true);
    try {
      const formData = new FormData(form.current);
      const userEmail = formData.get("user_email").trim();
      let userPhone = formData.get("user_phone").trim();

      // Check if email is already subscribed
      const emailExists = await checkEmailExists(userEmail);
      if (emailExists) {
        alert("You are already subscribed.");
        setIsLoading(false);
      }
      if (isSubscribed) {
        try {
          await axios.post(
            "https://api.brevo.com/v3/contacts",
            {
              email: userEmail,
              attributes: { SMS: userPhone, WHATSAPP: userPhone, FIRSTNAME: formData.get("user_name") },
              listIds: [10],
              updateEnabled: true,
            },
            {
              headers: {
                "api-key": "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",
                "Content-Type": "application/json",
              },
            }
          );
        } catch (error) {
          if (error.response && error.response.status === 400) {
            alert("Failed to subscribe. Please check your email and try again.");
            console.log(error.response);
          } else {
            alert("An unexpected error occurred during subscription. Please try again.");
          }
        }
      }
      const emailData = {
        sender: {
          name: formData.get("user_email"),
          email: "support@washyourstuff.com",
        },
        to: [{ email: "miyllc3@gmail.com" }],
        subject: `New Promotions from ${formData.get("user_name")}`,
        htmlContent: `
          <div style="font-family: Arial, sans-serif; line-height: 1.6; color: #000;">
            <p>Hello <strong style="color: #0000FF;">WYS Team</strong>,</p>
            <p>You got a New Promotions from <strong>${formData.get("user_name")}</strong></p>
            <table style="width: 70%; border-collapse: collapse; border: 1px solid #000;">
              <tr>
                <td style="width: 30%; border: 1px solid #000; padding: 8px;"><strong>Name</strong></td>
                <td style="border: 1px solid #000; padding: 8px;">${formData.get("user_name")}</td>
              </tr>
              <tr>
                <td style="width: 30%; border: 1px solid #000; padding: 8px;"><strong>Email</strong></td>
                <td style="border: 1px solid #000; padding: 8px;">${formData.get("user_email")}</td>
              </tr>
              <tr>
                <td style="width: 30%; border: 1px solid #000; padding: 8px;"><strong>Phone Number</strong></td>
                <td style="border: 1px solid #000; padding: 8px;">${formData.get("user_phone")}</td>
              </tr>
              <tr>
                <td style="width: 30%; border: 1px solid #000; padding: 8px;"><strong>Promotions Code</strong></td>
                <td style="border: 1px solid #000; padding: 8px;"> ${formData.get("user_Promotions") || "Not specified"}</td>
              </tr>
              <tr>
                <td style="width: 30%; border: 1px solid #000; padding: 8px;"><strong>Subscribed</strong></td>
                <td style="border: 1px solid #000; padding: 8px;">${formData.get("subscription_message")}</td>
              </tr>
            </table>
            <p>Best wishes,<br><strong style="color: #0000FF;">WYS Team</strong></p>
          </div>
        `,
      };

      await axios.post("https://api.brevo.com/v3/smtp/email", emailData, {
        headers: {
          "Content-Type": "application/json",
          "api-key": "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",
        },
      });

      setIsSuccess(true);
      form.current.reset();
      setSelectedPromotion(null);
      setIsSubscribed(false);
      setCaptcha("");
      setUserCaptchaInput("");
      generateCaptcha();
    } catch (error) {
      console.error("Error during email sending:", error);
      alert("An unexpected error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const PromotionsOptions = [{ value: "Free touchless wash", label: "Free touchless wash" }];

  return (
    <div className="PromotionsS2">
      <div className="PromotionsS2P1">promotion form</div>
      <div className="PromotionsS2P2">
        <form ref={form} onSubmit={sendEmailAfterVerification}>
          <div className="PromotionsS2P2V">
            {/* User Name */}
            <label className="input">
              <input className="input_field_Feed input_1" type="text" placeholder="Name *" name="user_name" required />
            </label>
            {/* Phone Number */}
            <label className="input">
              <PhoneInput
                country={"us"}
                value={phoneNumber}
                onChange={(phone) => setPhoneNumber(phone)}
                inputProps={{
                  name: "user_phone",
                  required: true,
                }}
                containerClass="phone-input-wrapper"
                inputClass="input_field_Feed input_1"
              />
            </label>
            {/* email */}
            <label className="input">
              <input className="input_field_Feed input_1" type="text" placeholder="Email *" name="user_email" required />
            </label>
            {/* Promo code */}
            <label className="input">
              <Select
                options={PromotionsOptions}
                placeholder="please select the promo code *"
                name="user_Promotions"
                styles={customSelectStyles}
                value={selectedPromotion}
                onChange={setSelectedPromotion}
              />
            </label>
            {/* Coinless app */}
            <div className="PromotionsS3T">Powered By: Coinless App.</div>
            <div className="PromotionsS3T">In order to redeem the offer, you agree to receive occasional text and email promotions & offers.</div>
            {/* terms and conditions */}
            <div className="PromotionsS3T">
              <input type="checkbox" className="PromotionsCheckBox" checked={isTermsAccepted} onChange={handleTermsCheckboxChange} />
              <label className="TermsLabel">
                Check <Link to="/Terms-And-Conditions">Terms and conditions and privacy policy</Link> for more information.
              </label>
            </div>
            {/* Error message display */}
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            {/* subscribe */}
            <div className="PromotionsS3T">
              <input type="checkbox" id="subscribeCheckbox" className="PromotionsCheckBox" checked={isSubscribed} onChange={handleSubscribeCheckboxChange} />
              <label htmlFor="subscribeCheckbox">Sign up for our email list for updates, promotions, and more.</label>
            </div>
            <input type="hidden" name="user_subscribe" value={isSubscribed ? "Yes" : "No"} />
            <input type="hidden" name="subscription_message" value={isSubscribed ? "User has subscribed to the newsletter." : "sorry user not"} />
            {/* Notes */}
            <div className="PromotionsS3T">
              *Free touchless wash for customers new to Wash Your Stuff Coinless.
              <br /> Brief feedback form required for existing Wash Your Stuff Coinless customers.
            </div>
            {/* submit button */}
            <div>
              <button className="PromotionsButton" type="submit">
                Submit <img src={HomeS3Right} alt="" className="PromotionsButtonImg" />
              </button>
            </div>
          </div>
        </form>
      </div>
      {/* Captcha Pop-up */}
      {isCaptchaOpen && (
        <div className="captchaModal">
          <div className="captchaModalContent">
            {isLoading ? (
              <p>Loading...</p>
            ) : isSuccess ? (
              <>
                <p>Thanks, your email has been sent, and one of our team members will contact you soon.</p>
                <button
                  onClick={() => {
                    setIsCaptchaOpen(false);
                    window.location.reload();
                  }}
                >
                  Close
                </button>
              </>
            ) : (
              <>
                <h3>Verify you are human</h3>
                <p>What is {captcha}?</p>
                <input type="text" placeholder="Enter the answer" value={userCaptchaInput} onChange={(e) => setUserCaptchaInput(e.target.value)} required />
                <button onClick={verifyCaptchaAndSendEmail}>Verify and Send</button>
                <button
                  onClick={() => {
                    setIsCaptchaOpen(false);
                    setUserCaptchaInput("");
                    generateCaptcha();
                  }}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
function PromotionsS3() {
  return (
    <>
      <div className="PromotionsS3">
        <div className="PromotionsS3P1">Wash Your Stuff locations</div>
        {/*  */}
        <div className="HomeS3P2">
          {/*  */}
          <div className="HomeS3P2B1">
            <img src={HomeS3First} alt="" className="HomeS3P2B1Img" />
            <h1 className="HomeS3P2B1H1">grand prairie</h1>
            <button className="HomeS3P2B1H2">NEW LOCATION</button>
            <Link to="/GrandPrairie">
              <button className="HomeS3P2B1Button">
                Wash services <img src={HomeS3Right} alt="" className="HomeS3RightImg" />
              </button>
            </Link>
          </div>
          {/*  */}
          <div className="HomeS3P2B1">
            <img src={HomeS3Second} alt="" className="HomeS3P2B1Img" />
            <h1 className="HomeS3P2B1H1">Grapevine</h1>
            <Link to="/Grapevine">
              <button className="HomeS3P2B1Button">
                Wash services <img src={HomeS3Right} alt="" className="HomeS3RightImg" />
              </button>
            </Link>
          </div>
          {/*  */}
          <div className="HomeS3P2B1">
            <img src={HomeS3Third} alt="" className="HomeS3P2B1Img" />
            <h1 className="HomeS3P2B1H1">Keller</h1>
            <Link to="/Keller">
              <button className="HomeS3P2B1Button">
                Wash services <img src={HomeS3Right} alt="" className="HomeS3RightImg" />
              </button>
            </Link>
          </div>
          {/*  */}
        </div>
      </div>
    </>
  );
}
