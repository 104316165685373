import React, { useState } from "react";
import "./Reviews.css";
import HomeS3SecondWeb from "../../Assets/images/Reviews/Grapevine.webp";
import HomeS3SecondMob from "../../Assets/images/Home/HomeS3SecondMob.webp";
import ReviewsGoogle from "../../Assets/svg/Reviews/ReviewsGoogle.svg";
import ReviewsYelp from "../../Assets/svg/Reviews/ReviewsYelp.svg";

import { useIsMobile } from "../../Assets/hooks/IsMobile/IsMobile";
export default function GrapevineReviews() {
  return (
    <>
      <div className="Reviews">
        <RS1 />
      </div>
    </>
  );
}

function RS1() {
  const isMobile = useIsMobile();

  return (
    <>
      <div className="RS1">
        <div className="RS1P1">
          <div className="RS1P1T1">Reviews</div>
          <div className="RS1P1T2">
            Share your thoughts!
            <br /> and we'll make sure your ride shines.
          </div>
        </div>
        {/*  */}
        <div className="RS1P2">
          {/*  */}
          <div className="RS1P2B1">
            <img src={isMobile ? HomeS3SecondMob : HomeS3SecondWeb} alt="grl self wash car" className="RS1P2B1Img" />
            <h1 className="RS1P2B1H1">Grapevine</h1>
            {/*  */}
            <div className="RS1P2B1V">
              <div className="RS1P1T2 RS1P2B1V1">REVIEW Grapevine on</div>
              <div className="RS1P2B1V2">
                <a href="https://g.page/r/CRyMQ4wQnx3dEAE/review" target="blank">
                  <img src={ReviewsGoogle} alt="" />
                </a>
                <a
                  href="https://www.yelp.com/writeareview/biz/W7livx8Hz_l5ml6Sa-KT2w?return_url=%2Fbiz%2FW7livx8Hz_l5ml6Sa-KT2w&review_origin=biz-details-war-button"
                  target="blank"
                >
                  <img src={ReviewsYelp} alt="" />
                </a>
              </div>
            </div>
            {/*  */}
          </div>
          {/*  */}
        </div>
      </div>
    </>
  );
}
