import React from "react";
import "./Home.css";
import "./Popup.css";
import { Link } from "react-router-dom";
import HeroHome from "../../Component/HomePage/HeroHome/HeroHome";
import HomeS2Img from "../../Assets/images/Home/HomeS2Img.webp";
import RightIcon from "../../Assets/svg/Home/RightIcon.svg";
import HomeS3Right from "../../Assets/svg/Home/HomeS3Right.svg";
import HomeS3First from "../../Assets/images/Home/HomeS3First.webp";
import HomeS3FirstMob from "../../Assets/images/Home/HomeS3FirstMob.webp";
import HomeS3Second from "../../Assets/images/Home/HomeS3Second.webp";
import HomeS3SecondMob from "../../Assets/images/Home/HomeS3SecondMob.webp";
import HomeS3Third from "../../Assets/images/Home/HomeS3Third.webp";
import HomeS3ThirdMob from "../../Assets/images/Home/HomeS3ThirdMob.webp";
import HomeS4Img1 from "../../Assets/images/Home/HomeS4Img1.webp";
import HomeS4Img2 from "../../Assets/images/Home/HomeS4Img2.webp";
import HomeS4Img3 from "../../Assets/images/Home/HomeS4Img3.webp";
import HomeS4Img4 from "../../Assets/images/Home/HomeS4Img4.webp";
import HomeS4Img5 from "../../Assets/images/Home/HomeS4Img5.webp";
import HomeS4Img6 from "../../Assets/images/Home/HomeS4Img6.webp";
import HomeS5Icon1 from "../../Assets/svg/Home/HomeS5Icon1.svg";
import HomeS5Icon2 from "../../Assets/svg/Home/HomeS5Icon2.svg";
import HomeS5Icon3 from "../../Assets/svg/Home/HomeS5Icon3.svg";
import Faqs from "../../Assets/hooks/Reuse_Component/Faqs/Faqs";
import Slider from "../../Assets/hooks/Reuse_Component/Slider/Slider";
import HomeS6Img1 from "../../Assets/images/Home/HomeS6Img1.webp";
import HomeS6Img2 from "../../Assets/images/Home/HomeS6Img2.webp";
import HomeS6Img3 from "../../Assets/images/Home/HomeS6Img3.webp";
import HomeS6Img4 from "../../Assets/images/Home/HomeS6Img4.webp";
import HomeS6Img5 from "../../Assets/images/Home/HomeS6Img5.webp";
import HomeS6Img6 from "../../Assets/images/Home/HomeS6Img6.webp";
import useIsMobile from "../../Assets/hooks/Reuse_Component/IsMobile/IsMobile";

export default function HomeContent() {
  return (
    <>
      <div className="Home_Container">
        <div className="HeroHomeS1">
          <HeroHome />
          <HomeS2 />
          <HomeS3 />
          <HomeS4 />
          <HomeS5 />
          <HomeS6 />
          <HomeS7 />
          <HomeS8 />
        </div>
      </div>
    </>
  );
}
// home s2
function HomeS2() {
  return (
    <>
      {/* Home S2 Web */}
      <div className="HomeS2">
        <div>
          <img src={HomeS2Img} alt="HP wash vintage car" className="HomeS2P1Img" />
        </div>
        <div className="HomeS2P2">
          <div className="HomeS2P2V1">About us</div>
          <div className="HomeS2P2V2">Wash Your Stuff!</div>
          <div className="HomeS2P2V3">
            <div>
              We believe that a self service car wash is the best way to wash a vehicle. There is no automated machine that can match the human element. <br />{" "}
              The self service carwash gives you the tools and location to detail your ride to your satisfaction..
            </div>
            <div className="">
              That being said we recognize that sometimes one doesn’t have time to self wash their vehicle. This is why we offer state of the art touch-free
              automatic carwashes at our facility. When you are strapped for time or want to maintain your clean car<span className="SpanBigger">,</span> just
              drive through our touch-free automatic carwash. It will wash your vehicle with no chance of scratching or denting your paint finish!
            </div>
          </div>
          <div className="HomeS2P2V4">
            <Link to="/About-us">
              Read More <img src={RightIcon} alt="" />
            </Link>
          </div>
        </div>
      </div>

      {/* Home S2 Mob */}
      <div className="HomeS2 HomeS2Mob">
        <div className="HomeS2P2 HomeS2MobP2">
          <div className="HomeS2P2V1">About us</div>
          <div className="HomeS2P2V2">Wash Your Stuff!</div>
          <div className="HomeS2P1ImgCont">
            <img src={HomeS2Img} alt="HP wash vintage car" className="HomeS2P1Img" />
          </div>
          <div className="HomeS2P2V3">
            <div>
              We believe that a self service car wash is the best way to wash a vehicle. There is no automated machine that can match the human element. The
              self service carwash gives you the tools and location to detail your ride to your satisfaction..
            </div>
            <br />
            <div className="">
              That being said we recognize that sometimes one doesn’t have time to self wash their vehicle. This is why we offer state of the art touch-free
              automatic carwashes at our facility. When you are strapped for time or want to maintain your clean car<span className="SpanBigger">,</span> just
              drive through our touch-free automatic carwash. It will wash your vehicle with no chance of scratching or denting your paint finish!
            </div>
          </div>
        </div>
        <div className="HomeS2P2V4">
          <Link to="/About-us">
            Read More <img src={RightIcon} alt="" />
          </Link>
        </div>
      </div>
    </>
  );
}
// home s3
function HomeS3() {
  const isMobile = useIsMobile();
  return (
    <>
      <div className="HomeS3">
        <div className="HomeS3P1">
          <h1>Our Location</h1>
          <h2>
            Find Us Near You <br /> we are open 24/7
          </h2>
          <p>
            Experience an extraordinary wash! <br /> Locate your nearest location and enjoy the Difference.
          </p>
        </div>
        <div className="HomeS3Get">
          <div className="">
            <Link to="/Locations">
              <button className="HomeS3P2B1ButtonTop">
                Get Direction <img src={HomeS3Right} alt="" className="HomeS3RightImg" />
              </button>
            </Link>
          </div>
          {/*  */}
          <div>
            <Link to="/Locations">
              <button className="HomeS3P2B1ButtonTop">
                Get Direction <img src={HomeS3Right} alt="" className="HomeS3RightImg" />
              </button>
            </Link>
          </div>
          {/*  */}
          <div>
            <Link to="/Locations">
              <button className="HomeS3P2B1ButtonTop">
                Get Direction <img src={HomeS3Right} alt="" className="HomeS3RightImg" />
              </button>
            </Link>
          </div>
        </div>
        {/*  */}
        <div className="HomeS3P2">
          {/* Button For Mob */}
          <div className="HomeS3P2B1ButtonTopMob">
            <Link to="/Locations">
              <button className="HomeS3P2B1ButtonTop">
                Get Direction <img src={HomeS3Right} alt="" className="HomeS3RightImg" />
              </button>
            </Link>
          </div>
          {/*  */}

          <div className="HomeS3P2B1">
            <img src={isMobile ? HomeS3FirstMob : HomeS3First} alt="gpl self wash car" className="HomeS3P2B1Img" />
            <h1 className="HomeS3P2B1H1">grand prairie</h1>
            <button className="HomeS3P2B1H2">NEW LOCATION</button>
            <Link to="/GrandPrairie">
              <button className="HomeS3P2B1Button">
                location services <img src={HomeS3Right} alt="" className="HomeS3RightImg" />
              </button>
            </Link>
          </div>
          {/* Button For Mob */}
          <div className="HomeS3P2B1ButtonTopMob">
            <Link to="/Locations">
              <button className="HomeS3P2B1ButtonTop">
                Get Direction <img src={HomeS3Right} alt="" className="HomeS3RightImg" />
              </button>
            </Link>
          </div>
          {/*  */}
          {/*  */}
          <div className="HomeS3P2B1">
            <img src={isMobile ? HomeS3SecondMob : HomeS3Second} alt="grl self wash car" className="HomeS3P2B1Img" />
            <h1 className="HomeS3P2B1H1">Grapevine</h1>
            <Link to="/Grapevine">
              <button className="HomeS3P2B1Button">
                location services <img src={HomeS3Right} alt="" className="HomeS3RightImg" />
              </button>
            </Link>
          </div>
          {/* Button For Mob */}
          <div className="HomeS3P2B1ButtonTopMob">
            <Link to="/Locations">
              <button className="HomeS3P2B1ButtonTop">
                Get Direction <img src={HomeS3Right} alt="" className="HomeS3RightImg" />
              </button>
            </Link>
          </div>
          {/*  */}
          {/*  */}
          <div className="HomeS3P2B1">
            <img src={isMobile ? HomeS3ThirdMob : HomeS3Third} alt="Klrl self wash car" className="HomeS3P2B1Img" />
            <h1 className="HomeS3P2B1H1">Keller</h1>
            <Link to="/Keller">
              <button className="HomeS3P2B1Button">
                location services <img src={HomeS3Right} alt="" className="HomeS3RightImg" />
              </button>
            </Link>
          </div>
          {/*  */}
        </div>
      </div>
    </>
  );
}
// home s4
function HomeS4() {
  return (
    <>
      <div className="HomeS4">
        <div className="HomeS4P1">
          <h1>OUR SERVICES</h1>
          <h2>
            From Cars to Carpets<span className="SpanBiggerMore">,</span> We Clean It All
          </h2>
        </div>
        <div className="HomeS4P2">
          <Link to="/Services#SelfService">
            <div className="HomeS4P2Box">
              <img src={HomeS4Img1} alt="wash your stuff car wash services" />
              <h3>Self-Service wash</h3>
            </div>
          </Link>
          <Link to="/Services#TouchFree">
            <div className="HomeS4P2Box">
              <img src={HomeS4Img2} alt="wash your stuff touch free car wash" />
              <h3>Touch-Free Wash</h3>
            </div>
          </Link>
          <Link to="/Services#PetPays">
            <div className="HomeS4P2Box SmallText">
              <img src={HomeS4Img3} alt="wash your stuff pet wash bays" />
              <h3>Pet Bays</h3>
            </div>
          </Link>
          <Link to="/Services#PetPark">
            <div className="HomeS4P2Box SmallText">
              <img src={HomeS4Img4} alt="wash your stuff pet park" />
              <h3>Pet Park</h3>
            </div>
          </Link>
          <Link to="/Services#Vacuums">
            <div className="HomeS4P2Box SmallText">
              <img src={HomeS4Img5} alt="wash your stuff vacuums car wash" />
              <h3>Vacuums</h3>
            </div>
          </Link>
          <Link to="/Services">
            <div className="HomeS4P2Box BigText">
              <img src={HomeS4Img6} alt="wash your stuff self services car wash" />
              <h3>
                trucks<span className="SpanBigger">,</span> bikes<span className="SpanBigger">,</span> boats & more
              </h3>
            </div>
          </Link>
        </div>
      </div>
      {/*  */}
      {/* For Mob */}
      {/*  */}
      <div className="HomeS4Mob">
        <div className="HomeS4P1">
          <h1>OUR SERVICES</h1>
          <h2>
            From Cars to Carpets<span className="SpanBiggerMore">,</span> We Clean It All
          </h2>
        </div>
        <div className="HomeS4P2">
          <Link to="/Services#SelfServiceMob">
            <div className="HomeS4P2Box">
              <img src={HomeS4Img1} alt="wash your stuff car wash services" />
              <h3>Self-Service wash</h3>
            </div>
          </Link>
          <Link to="/Services#TouchFreeMob">
            <div className="HomeS4P2Box">
              <img src={HomeS4Img2} alt="wash your stuff touch free car wash" />
              <h3>Touch-Free Wash</h3>
            </div>
          </Link>
          <Link to="/Services#PetPaysMob">
            <div className="HomeS4P2Box SmallText">
              <img src={HomeS4Img3} alt="wash your stuff pet wash bays" />
              <h3>Pet Bays</h3>
            </div>
          </Link>
          <Link to="/Services#PetParkMob">
            <div className="HomeS4P2Box SmallText">
              <img src={HomeS4Img4} alt="wash your stuff pet park" />
              <h3>Pet Park</h3>
            </div>
          </Link>
          <Link to="/Services#VacuumsMob">
            <div className="HomeS4P2Box SmallText">
              <img src={HomeS4Img5} alt="wash your stuff vacuums car wash" />
              <h3>Vacuums</h3>
            </div>
          </Link>
          <Link to="/Services">
            <div className="HomeS4P2Box BigText">
              <img src={HomeS4Img6} alt="wash your stuff self services car wash" />
              <h3>
                trucks<span className="SpanBigger">,</span> bikes<span className="SpanBigger">,</span> boats & more
              </h3>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}
// home s5
function HomeS5() {
  return (
    <>
      <div className="HomeS5">
        <div className="HomeS5P1">
          <h1>Countless Satisfied cUSTOMERS</h1>
        </div>
        <div className="HomeS5P2">
          {/*  */}
          <div className="HomeS5P2Box">
            <div className="Item1">
              +300<span className="SpanBiggerMore">,</span>000
            </div>
            <div className="Item2Cont">
              <div className="Item3 Box3H">Clean Vehicle</div>
              <div className="Item2 BoxImage">
                <img src={HomeS5Icon1} alt="" className="HomeS5Icon3" />
              </div>
            </div>
          </div>
          {/*  */}
          <div className="HomeS5P2Box">
            <div className="Item1">
              +240<span className="SpanBiggerMore">,</span>000
            </div>
            <div className="Item2Cont">
              <div className="Item3 Box3H">Happy Customer</div>
              <div className="Item2 BoxImage">
                <img src={HomeS5Icon2} alt="" className="HomeS5Icon3" />
              </div>
            </div>
          </div>
          {/*  */}
          <div className="HomeS5P2Box">
            <div className="Item1">
              +3<span className="SpanBiggerMore">,</span>000
            </div>
            <div className="Item2Cont">
              <div className="Item3 Box3H">Clean Pet</div>
              <div className="Item2 BoxImage">
                <img src={HomeS5Icon3} alt="" className="HomeS5Icon3" />
              </div>
            </div>
          </div>
          {/*  */}
        </div>
        <div className="HomeS5P3">
          <h1>
            Be one of our customers! <br />
            We promise making your car shine brighter than ever.
          </h1>
        </div>
      </div>
    </>
  );
}
// home s6

const slides = [
  {
    paragraph:
      "Wash Your Stuff is a great place for people to bring their toys and nice cars. Don’t want swirl marks from tunnel washes. Place is always clean and employees always helpful. All bays are working when I have been here. I drive past several washes to get to this one. Worth it. Takes card which is very convenient.",
    clientName: "Tim Worrell",
    stars: 5,
    branchName: "Keller location",
    image: HomeS6Img1,
    title: "Keller Review 1",
  },
  {
    paragraph: (
      <>
        "The absolute best self service car wash ever! I actually commuted to the Grapevine location a few times just to wash my car. So thankful there’s a
        place local now!"
      </>
    ),
    clientName: "Riley Mahoney",
    stars: 5,
    branchName: "Grand Prairie location",
    image: HomeS6Img2,
    title: "Grand Prairie review 1",
  },
  {
    paragraph: (
      <>
        I love the idea of a car wash + a pet wash. The place is clean<span className="SpanBigger">,</span> cheap<span className="SpanBigger">,</span> and
        convenient! <br /> <br /> My dog is an older dog and it’s harder to pick her up nowadays without causing her discomfort. We don’t utilize tub ramps
        <span className="SpanBigger">,</span> so I like that the cords and such are long enough to wash her outside the tub where she can remain comfortably on
        the ground.
      </>
    ),
    clientName: "Elizabeth Disney",
    stars: 5,
    branchName: "Grapevine location",
    image: HomeS6Img3,
    title: "Grapevine Review 1",
  },
  {
    paragraph: (
      <>
        Amazing place<span className="SpanBigger">,</span> keeps adding more and more features<span className="SpanBigger">,</span> the owner is very kind and
        helpful. He personally keeps a check on all the equipment. I have been to all the self serve car washes in the west half of DFW and found that this is
        the best.{" "}
      </>
    ),
    clientName: "Mathai Paul",
    stars: 5,
    branchName: "Keller location",
    image: HomeS6Img4,
    title: "Keller Review 2",
  },
  {
    paragraph: (
      <>
        I love this car wash! You can go in the bay or you can do it yourself<span className="SpanBigger">,</span> you can control it all with an app so no need
        for quarters unless you want to pay it like that. This even has a dryer for the end. And vacuums have the fragrance or the wash as well as good vacuums
      </>
    ),
    clientName: "Jennifer Morrison",
    stars: 5,
    branchName: "Grand Prairie location",
    image: HomeS6Img5,
    title: "Grand Prairie review 2",
  },
  {
    paragraph: (
      <>
        Easily my most favorite self serve car wash ever. <br /> Even has an automated no-brush wash. <br /> You can even wash your pets here. They won my heart
        at this<span className="SpanBigger">,</span> PLUS a dog park!! <br /> This place won my heart over for a place to wash my car when I’m not home! Best
        part is it’s clean and in a good part of town 🥰
      </>
    ),
    clientName: "Jepeps",
    stars: 5,
    branchName: "Grapevine location",
    image: HomeS6Img6,
    title: "Grapevine Review 2",
  },
];

function HomeS6() {
  return (
    <>
      <div className="HomeS6">
        <div className="HomeS6P1">
          <h1>what our clients says</h1>
        </div>
        <Slider slides={slides} />
      </div>
    </>
  );
}

// home s7
const faqDataHome = [
  {
    question: "What are the working hours for all locations?",
    answer: (
      <>
        We operate 24/7 in all our locations. <br /> (Check our locations page for directions)
      </>
    ),
  },
  {
    question: "Does every vehicle have different cost?",
    answer: (
      <>
        No<span className="SpanBigger">,</span>
        <br /> Self Service wash it is time based<span className="SpanBigger">,</span> It’s the same exact price per time unit. <br /> Touch-free Automatic wash
        is package based<span className="SpanBigger">,</span> Each package has a different price depending on the options. <br /> (Check Services Page to know
        more bout each branch services & prices)
      </>
    ),
  },
];

function HomeS7() {
  return (
    <>
      <div className="HomeS7">
        <div className="HomeS7P1">
          <div className="HomeS7P1V1">Frequent asked questions</div>
          <div className="HomeS7P1V2">
            Everything you want to know about <br /> our car wash bays<span className="SpanBigger">,</span> dimensions and services.
          </div>
        </div>
        <div>
          <Faqs faqData={faqDataHome} />
          <div className="HomeS7P1V3">
            <Link to="/Faqs">
              View All FAQs <img src={RightIcon} alt="" className="HomeS7P1V3Icon" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
// home s8
function HomeS8() {
  return (
    <>
      <div className="HomeS8">
        <div className="HomeS8P2">free $5 credit for your feedback</div>
        <div className="HomeS8P3">
          we value your opinion and would love to hear from you . please send us your feedback ( positive or negative ) and will send you free $ 5 credit toward
          your next wash
        </div>
        <div className="HomeS8P4">
          <Link to="Feedback">
            <button>
              Feedback Us <img src={HomeS3Right} alt="" className="HomeS7P1V3Icon" />
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}
